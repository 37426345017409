<script>
import store from '@/store'

export default {
    data() {
        return {
            doorId: 0,
            doorCheckResult: null,
            checkResultWatcher: null
        }
    },
    methods: {
        watchCheckResult() {
            this.checkResultWatcher = store.watch(
                (state, getters) => getters.doorCheckResult,
                (val) => {
                    if (val) { this.doorCheckResult = val }
                }
            )
        },
        checkControl() {
            let status
            if (this.doorCheckResult) status = this.doorCheckResult[this.doorId]
            if ((status === 0) || ((status & 1) == 1)) store.commit('setSnackBar', { message: '儲格狀態異常', color: 'error' })
            else if ((status & 2) == 2) {this.doorStatus = status; return true; }
            else store.commit('setSnackBar', { message: '檢查失敗，請稍後再試', color: 'error' })
            return false
        }
    },
    activated() {
        this.watchCheckResult()
    },
    deactivated() {
        // unwatch control result
        if (this.checkResultWatcher) this.checkResultWatcher()
    }
}
</script>
