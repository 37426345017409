<template>
    <v-card flat>
        <v-card-title class="subheading pa-1 pt-0">
            {{ $t('doorSelector.cabinet-number') }}&nbsp;&nbsp;{{ boxId }}
            <v-btn small outline color="primary" class="body-2" @click="back">{{ $t('doorSelector.reselect') }}</v-btn>
        </v-card-title>
        <v-card-text class="pa-1 pt-0">
            <v-alert v-show="warnMsg" color="error" outline class="subheading">
                <b>{{warnMsg}}</b>
            </v-alert>
            <v-container grid-list-xs fluid class="pa-0">
                <v-layout row wrap>
                    <v-flex v-for="door in availableDoors" :key="door.id" xs3 sm2 md2 lg1>
                        <v-card class="clickable" @click="select(door)" :dark="door.id === doorId">
                            <v-card-title class="justify-center title">{{door.id}}</v-card-title>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
    </v-card>
</template>

<script>
import axios from 'axios'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import store, { HostUrl } from '@/store'
import { ASSIGN_VIEW, getAxiosConfig } from '@/utils/AuthService'
import i18n from '@/i18n'

export default {
    name: 'door-inspector',
    components: { WaitingDialog },
    mixins: [ WaitDialogControl ],
    props: {
        enable: {
            type: Boolean,
            default: false
        },
        boxId: {
            type: String,
            required: true
        },
        doorId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            preDoorId: 0,
            availableDoors: [],
            rate: null,
            warnMsg: null
        }
    },
    watch: {
        enable(val) {
            if (val) this.getAvailableDoors()
            else this.init()
        },
        doorId(val, oldVal) {
            this.preDoorId = oldVal
        }
    },
    methods: {
        init() {
            this.availableDoors = []
            this.rate = null
            this.warnMsg = null
        },
        back() {
            this.init()
            this.$emit('update:doorId', 0)
            this.$emit('back')
        },
        select(door) {
            this.$emit('update:doorId', door.id)
            this.checkDoorAvailability(door.id)
        },
        checkDoorAvailability(doorId) {
            let doorInfo = this.availableDoors.find(d => d.id === doorId)
            if (doorInfo) {
                this.warnMsg = null
                this.$emit('select', doorInfo, this.rate, true)
            } else if (this.availableDoors.length > 0 && doorId > 0) {
                this.warnMsg = `${i18n.t('doorSelector.cell')} ${doorId} ${i18n.t('doorSelector.unavailable')}`
                this.$emit('select', null, this.rate, false)
            } else if (this.availableDoors.length === 0) {
                this.warnMsg = i18n.t('doorSelector.no-cell')
                this.$emit('select', null, this.rate, false)
            } //else this.doorId === 0 do nothing
        },
        getAvailableDoors() {
            if (!this.boxId) {
                this.warnMsg = i18n.t('doorSelector.cabinet-not-set')
                return
            }
            if (this.boxId.length != 6) {
                this.warnMsg = i18n.t('doorSelector.cabinet-not-set')
                return
            }
            if (store.getters.user.box_owner != null && !store.getters.user.box_owner.includes(this.boxId)) {
                this.warnMsg = i18n.t('doorSelector.cabinet-not-set')
                return
            }
            //console.log('[api door_check] time:'+new Date().getTime())
            this.showDialog(i18n.t('doorSelector.load-cell-status'), i18n.t('doorSelector.load-fail'))
            setTimeout(() => {
                this.closeDialog()
                this.apiGetAvailableDoors()
            }, 3000)
        },
        async apiGetAvailableDoors() {
            this.showDialog(i18n.t('doorSelector.load-cell-status'), i18n.t('doorSelector.load-fail'))
            //console.log('[22 - api door_check] time:'+new Date().getTime())
            let url = `${HostUrl}/api/box/${this.boxId}/doors/check`
            if (store.getters.fromViews === ASSIGN_VIEW.LOCK)
                url = `${HostUrl}/api/box/${this.boxId}/doors/check/lock`
            let self = this
            const getPromise = axios.get(url, getAxiosConfig())
                .then(function(response) {
                    if (response.data.code !== 0) throw Error(response.data.message)
                    self.rate = response.data.rate
                    let doors = response.data.doors
                    let is_availableDoors = Object.keys(doors).filter((doorId) => {
                        if (doorId)
                            return true //doors[doorId]
                    })

                    //檢查 doorId 不為 0，轉換成整數(以10進位)
                    self.availableDoors = is_availableDoors.map((doorId) => {
                        if(doorId !== 0){
                            doors[doorId].id = parseInt(doorId, 10)
                            return doors[doorId]
                        }
                    })
                    if (self.preDoorId === 0 && self.doorId > 0) {
                        self.checkDoorAvailability(self.doorId)
                        self.preDoorId = self.doorId
                    }
                    return doors
                })
                .catch(function(error) {
                    self.warnMsg = error.message
                })
                await Promise.all([getPromise]).then(() => {
                    self.closeDialog()
                })
        }
    }
}
</script>

<style scoped>
</style>
