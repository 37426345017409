<script>
import store from '@/store'
import BoxIdWatcherMixin from '@/mixins/BoxIdWatcher'
import DoorInspector from '@/components/DoorInspector'
import WaitingDialog from '@/components/WaitingDialog'
import QRReader from '@/components/QRReader'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import { SideBtnType } from '@/store'
import i18n from '@/i18n'

export const TransTypes = {
    Delivery: 1,
    Leave: 2,
    LongLeave: 3,
    StoreHost: 4
}

export default {
    components: { WaitingDialog, QRReader, DoorInspector },
    mixins: [ BoxIdWatcherMixin, WaitDialogControl ],
    props: {
        box: {
            type: String,
            default: ''
        },
        door: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            titles: [],
            preStepNo: 0,
            stepNo: 1,
            doorId: 0,
            doorInfo: null,
            rate: null,
            doorStatus: -1,
            camera: 'off'
            // showGoButtomBtn: false
        }
    },
    watch: {
        boxId() {
            if (this.boxId.length === 6 && /\d{6}/.test(this.boxId)) this.setStepNo(2)
        },
        checkResult() {
            this.closeDialog()

            let checkOk = this.checkControl()
            if (checkOk) {
                this.nextStep()
            }
        }
    },
    computed: {
        doorRate() {
            if (this.rate && this.doorInfo) {
                let styleRate = this.rate.rates[this.doorInfo.style]
                return {
                    startUnit: this.rate.start_unit,
                    contiUnit: this.rate.conti_unit,
                    startRate: styleRate.start_rate,
                    contiRate: styleRate.conti_rate,
                    monthRate: styleRate.month_rate
                }
            } else return {}
        }
    },
    methods: {
        init() {
            store.commit('setMQTTPayload', null)
            this.boxId = ''
            this.preStepNo = 0
            this.stepNo = 1
            store.commit('setTitle', this.titles[this.stepNo - 1])
            this.doorId = 0
            this.doorInfo = null
            this.emptyDialog = false
            this.dialogMessage = i18n.t('putIn.cabinet-unavailable')
        },
        setStepNo(no) {
            // console.log('setStepNo', no)
            if (no === 1) {
                this.init()
                this.camera = 'auto'
            } else this.camera = 'off'
            this.preStepNo = this.stepNo
            this.stepNo = no
            store.commit('setTitle', this.titles[this.stepNo - 1])
        },
        nextStep() {
            this.setStepNo(Math.min(this.stepNo + 1, this.titles.length))
        },
        preStep() {
            this.setStepNo(Math.max(this.stepNo - 1, 1))
        },
        onQuery(query) {
            store.commit('setTargetBoxId', query.box_id)
            this.boxId = query.box_id
            this.doorId = query.door_id
        },
        selectDoor(door, rate) {
            this.doorInfo = door
            this.rate = rate
        },
        initShowButtomBtn() {
            this.showGoButtomBtn = false
            console.log('initShowButtomBtn', this.showGoButtomBtn)
        }
    },
    activated() {
        store.commit('setSideBtnType', SideBtnType.Back)
        this.init()
        if (this.box.length === 6 && this.door > 0) this.onQuery({box_id: this.box, door_id: this.door})
        else this.camera = 'auto'
    },
    deactivated() {
        this.camera = 'off'
        store.commit('setSideBtnType', SideBtnType.Navi)
    }
}
</script>
